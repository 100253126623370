export const representationData =  [
  {
    name: '1D Representations',
    itemStyle: {color: '#90cec0'},
    children: [
      {
        name: 'SMILES',
        itemStyle: {color: '#c7e6df'},
        children: [
          { name: 'SELFIES', value: 1.1, itemStyle: { color: '#a695cc' } },
          { name: 'BAN', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'MoLFormer', value: 1, itemStyle: { color: '#d2cae5' } },
        ]
      },
      {
        name: 'Molecular Fingerprint',
        itemStyle: {color: '#c7e6df'},
        children: [
          { name: 'Daylight', value: 1.1, itemStyle: { color: '#a695cc' } },
          { name: 'ECFP2', value: 1, itemStyle: { color: '#a695cc' } },
          { name: 'ECFP4', value: 1, itemStyle: { color: '#a695cc' } },
          { name: 'ECFP6', value: 1, itemStyle: { color: '#a695cc' } },
          { name: 'MACCS', value: 1, itemStyle: { color: '#a695cc' } },
          { name: 'PubChem', value: 1, itemStyle: { color: '#a695cc' } },
          { name: 'AVALON', value: 1, itemStyle: { color: '#a695cc' } },
          { name: 'MolMapNet', value: 1.1, itemStyle: { color: '#d2cae5' } },
        ]
      },
      {
        name: 'Molecular Descriptor',
        itemStyle: {color: '#c7e6df'},
        children: [
          { name: 'RDKit2d', value: 1.1, itemStyle: { color: '#a695cc' } },
          { name: 'Mordred', value: 1.1, itemStyle: { color: '#a695cc' } }
        ]
      }
    ]
  },
  {
    name: '2D Representations',
    itemStyle: {color: '#5f5582'},
    label: {color: '#dfe0e3' },
    children: [
      {
        name: 'Molecular Graph',
        itemStyle: {color: '#afaac0'},
        children: [
          { name: 'AttentiveFP', value: 1.2, itemStyle: { color: '#d2cae5' } },
          { name: 'N-Gram-RF', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'N-Gram-XGB', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'TrimNet', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'GCN', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'GIN', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'MolCLR', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'KANO', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'KPGT', value: 1, itemStyle: { color: '#d2cae5' } },
          { name: 'PharmHGT', value: 1, itemStyle: { color: '#d2cae5' } }
        ]
      },
      {
        name: 'Molecular Image',
        itemStyle: {color: '#afaac0'},
        children: [
          { name: 'ImageMol', value: 2, itemStyle: { color: '#d2cae5' } }
        ]
      }
    ]
  },
  {
    name: '3D Representations',
    itemStyle: {color: '#41475e'},
    label: {color: '#dfe0e3' },
    children: [
      {
        name: 'Molecular Fingerprint',
        itemStyle: {color: '#a0a3ae'},
        children: [
          { name: '3DFP', value: 1.8, itemStyle: { color: '#d2cae5' } },
          { name: 'E3FP', value: 1.8, itemStyle: { color: '#d2cae5' } }
        ]
      },
      {
        name: 'geometry',
        itemStyle: {color: '#a0a3ae'},
        children: [
          { name: 'SphereNet', value: 1.8, itemStyle: { color: '#d2cae5' } },
          { name: 'UniMol', value: 1.8, itemStyle: { color: '#d2cae5' } }
        ]
      }
    ]
  },
  {
    name: 'Hybrid Representations',
    itemStyle: {color: '#d1956a'},
    children: [
      {
        name: 'Molecular Graph+\ngeometry',
        itemStyle: {color: '#e8cab4'},
        children: [
          { name: 'GraphMVP', value: 1.8, itemStyle: { color: '#d2cae5' } },
          { name: '3d Infomax', value: 1.5, itemStyle: { color: '#d2cae5' } }
        ]
      },
      {
        name: 'SMILES+\nMolecular Graph+\nGeometry',
        itemStyle: {color: '#e8cab4', lineHeigth: 1},
        children: [
          { name: 'PremuNet', value: 3, itemStyle: { color: '#d2cae5' } }
        ]
      }
    ]
  },
]
export const datasetsData =  [
  {
    name: 'Physiology',
    itemStyle: {color: '#a695cc'},
    children: [
      {
        name: 'BBBP',
        itemStyle: {color: '#b8aad6'},
        children: [{ name: '2048', value: 1, itemStyle: {color: '#d2cae5'} }],
      },
      {
        name: 'ToX21',
        itemStyle: {color: '#b8aad6'},
        children: [{ name: '7831', value: 1, itemStyle: {color: '#d2cae5'} }],
      },
      {
        name: 'SIDER',
        itemStyle: {color: '#b8aad6'},
        children: [{ name: '1427', value: 1, itemStyle: {color: '#d2cae5'} }],
      },
      {
        name: 'ToXCast',
        itemStyle: {color: '#b8aad6'},
        children: [{ name: '8597', value: 1, itemStyle: {color: '#d2cae5'} }],
      },
      {
        name: 'ClinTox',
        itemStyle: {color: '#b8aad6'},
        children: [{ name: '1484', value: 1, itemStyle: {color: '#d2cae5'} }],
      },
    ],
  },
  {
    name: 'Biophysics',
    itemStyle: {color: '#41475e'},
    label: {color: '#dfe0e3' },
    children: [
      {
        name: 'BACE',
        itemStyle: {color: '#676c7e'},
        label: {color: '#dfe0e3' },
        children: [{ name: '1513', value: 1, itemStyle: {color: '#a0a3ae'}, label: {color: '#dfe0e3' }, }],
      },
      {
        name: 'HIV',
        itemStyle: {color: '#676c7e'},
        label: {color: '#dfe0e3' },
        children: [{ name: '41127', value: 1, itemStyle: {color: '#a0a3ae'}, label: {color: '#dfe0e3' }, }],
      },
      {
        name: 'MUV',
        itemStyle: {color: '#676c7e'},
        label: {color: '#dfe0e3' },
        children: [{ name: '93087', value: 1, itemStyle: {color: '#a0a3ae'}, label: {color: '#dfe0e3' },}],
      },
    ],
  },
  {
    name: 'Physical chemistry',
    label: {color: '#dfe0e3' },
    itemStyle: {color: '#5f5582'},
    children: [
      {
        name: 'ESOL',label: {color: '#dfe0e3' },
        itemStyle: {color: '#7f779b'},
        children: [{ name: '1128', value: 1, itemStyle: {color: '#afaac0'},label: {color: '#dfe0e3' }, }],
      },
      {
        name: 'FreeSolv',label: {color: '#dfe0e3' },
        itemStyle: {color: '#7f779b'},
        children: [{ name: '642', value: 1, itemStyle: {color: '#afaac0'},label: {color: '#dfe0e3' }, }],
      },
      {
        name: 'Lipophilicity',label: {color: '#dfe0e3' },
        itemStyle: {color: '#7f779b'},
        children: [{ name: '4200', value: 1, itemStyle: {color: '#afaac0'},label: {color: '#dfe0e3' }, }],
      },
    ],
  },
  {
    name: 'Quantum mechanics',
    itemStyle: {color: '#d1956a'},
    children: [
      {
        name: 'QM8',
        itemStyle: {color: '#daaa88'},
        children: [{ name: '21786', value: 1, itemStyle: {color: '#e8cab4'} }],
      },
      {
        name: 'QM9',
        itemStyle: {color: '#daaa88'},
        children: [{ name: '133885', value: 1, itemStyle: {color: '#e8cab4'} }],
      },
    ],
  },
  {
    name: 'SARS-CoV-2',
    itemStyle: {color: '#90cec0'},
    children: [
      {
        name: 'HEK293',
        itemStyle: {color: '#a6d8cd'},
        children: [{ name: '4359', value: 1, itemStyle: {color: '#c7e6df'} }],
      },
      {
        name: 'CPE',
        itemStyle: {color: '#a6d8cd'},
        children: [{ name: '785', value: 1, itemStyle: {color: '#c7e6df'} }],
      },
      {
        name: 'MERS-CoV',
        itemStyle: {color: '#a6d8cd'},
        children: [{ name: '2500', value: 1, itemStyle: {color: '#c7e6df'} }],
      },
      {
        name: 'AlphaLISA',
        itemStyle: {color: '#a6d8cd'},
        children: [{ name: '1040', value: 1, itemStyle: {color: '#c7e6df'} }],
      },
    ],
  },
]
