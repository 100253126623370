import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/HomeView.vue')
  },
  {
    path: '/property-prediction',
    name: 'PropertyPrediction',
    component: () => import("@/views/propertyPrediction/index.vue")
  },
  {
    path: '/property-prediction/result',
    name: 'propertyResult',
    component: () => import("@/views/propertyPrediction/PropertyResult.vue")
  },
  {
    path: '/representation',
    name: 'RepresentationGeneration',
    component: () => import('@/views/representationGeneration/index.vue')
  },
  {
    path: '/representation/result',
    name: 'GenerationResult',
    component: () => import('@/views/representationGeneration/GenerationResult.vue')
  },
  {
    path: '/comprehensive-results',
    name: 'ComprehensiveResults',
    component: () => import('@/views/benchmark/ComprehensiveResults.vue')
  },
  {
    path: '/original-experimental-results',
    name: 'OriginalExperimentalResults',
    component: () => import('@/views/benchmark/OriginalExperimentalResults.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/Download.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('@/views/help/HelpView.vue')
  }
]

const router = new VueRouter({
  // mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})

export default router
