<script>
export default {
  name: "FooterView"
}
</script>

<template>
  <el-footer class="footer">
    <img src="@/assets/amor.png" alt="描述图片内容" class="logo" />
    <div class="text">
      <span>© 2019-2024 copyright</span>
      <span>Contact us</span>
      <span>Reference</span>
      <a href="https://info.flagcounter.com/DxLx" target="_blank" v-show="$route.path === '/home'">
        <img src="https://s01.flagcounter.com/count/DxLx/bg_b4a1d5/txt_3F4760/border_b4a1d5/columns_3/maxflags_6/viewers_0/labels_0/pageviews_0/flags_0/percent_0/" alt="Flag Counter" border="0">
      </a>
    </div>
  </el-footer>
</template>

<style scoped lang="less">
.footer {
  text-align: center;
  line-height: 1.36rem;
  display: flex;
  justify-content: space-between;
  column-gap: 10%;
  background: transparent;
  height: 3.18rem !important;
  align-items: flex-end;
  padding: 0 2rem 1.5rem 2rem;
  font-family: "Arial", "Times New Roman", "Microsoft YaHei", sans-serif;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  span {
    font-size: 0.81rem;
    line-height: 1.09rem;
    color: #3F4760;
    font-weight: bold;
    cursor: pointer;
  }
  .logo {
    width: 2.73rem;
    height: 3.18rem;
    object-fit: contain;
    margin-right: 1rem;
  }
  .text {
    //width: 28.95rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
      margin-left: 2rem;
    }
  }
}
a {
  margin-bottom: -0.2rem;
  margin-left: 2rem;
  img {
    height: 3rem;
    object-fit: contain;
  }
}
@media (min-width: 1000px) {
  .footer {
    width: 62rem;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>
